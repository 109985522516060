import React from "react";
import "./App.css";
import Home from "../src/components/home/home.jsx";
import Navbar from "../src/components/nav/navbar.jsx";
import About from "../src/components/about/about.jsx";
import Contact from "../src/components/contact/contact.jsx";
import Footer from "../src/components/footer/footer.jsx";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={
            <>
              <div id="home">
                <Home />
              </div>
              <div id="about">
                <About />
              </div>
              <div id="contact">
                <Contact />
              </div>
            </>
          }/>
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default App;
