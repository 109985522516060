import React from 'react';
import './form.css'

const CareerPopup = ({ togglePopup }) => {
  return (
    <div className="career-popup-overlay" onClick={togglePopup}>
      <div className="career-popup" onClick={(e) => e.stopPropagation()}>
        <h2>i need a job</h2>
        <form  method="POST" action="https://formsubmit.co/betterjobkerala@gmail.com" enctype="multipart/form-data" >
          <div className="form-group mt-3">
            <label htmlFor="" ></label>
           <input type="text" name="name" placeholder='Name' id="" />
          </div>

          <div className="form-group mt-3">
          <label htmlFor="" ></label>
            <input type="email" id="email" name="Email" placeholder='Email' required />
          </div>

          <div className="form-group mt-3">
          <label htmlFor="" ></label>
            <input type="tel" id="phoneNumber" name="phoneNumber" placeholder='Contact' required />
          </div>
          <div className='form-group mt-3'>
            <select name="Timing" id="">
            <option value="">Type of Jobs</option>
          <option value="Part Time">Part Time</option>
          <option value="Full Time">Full Time</option>
          <option value="Remote">Remote</option>
            </select>
          </div>

          <div className="form-group mt-3">
            <label htmlFor=""></label>
          <select id="jobrole" name="Designation">
          <option value="">Designation</option>
            <option value="Accountant">Accountant</option>
            <option value="Accounting Clerk">Accounting Clerk</option>
            <option value="Advertising Manager">Advertising Manager</option>
            <option value="Architect">Architect</option>
            <option value="Art Director"> Art Director</option>
            <option value="Auto Mechanic">Auto Mechanic</option>
            <option value="Assistant Manager">Assistant Manager</option>
            <option value="Auditor">Auditor</option>
            <option value="Business Analyst">Business Analyst</option>
            <option value="Business Development Executive">
              Business Development Executive
            </option>
            <option value="Call Center Executive">
              Call Center Executive
            </option>
            <option value="Carpenter">Carpenter</option>
            <option value="Chemical Engineer">Chemical Engineer</option>
            <option value="Chef">Chef</option>
            <option value="Civil Engineer"> Civil Engineer</option>
            <option value="Content Writer">Content Writer</option>
            <option value="Copywriter"> Copywriter</option>
            <option value="Customer Service Executive">
              Customer Service Executive
            </option>
            <option value="Cybersecurity Analyst">
              Cybersecurity Analyst
            </option>
            <option value="Data Analyst"> Data Analyst</option>
            <option value="Data Entry Operator"> Data Entry Operator</option>
            <option value="Digital Marketing Executive">
              Digital Marketing Executive
            </option>
            <option value="Driver">Driver</option>
            <option value="Design Engineer">Design Engineer</option>
            <option value="Electrical Engineer">Electrical Engineer</option>
            <option value="Fashion Designer">Fashion Designer</option>
            <option value="Financial Analyst">Financial Analyst</option>
            <option value="Front Office Executive">
              Front Office Executive
            </option>
            <option value="Graphic Designer"> Graphic Designer</option>
            <option value="Gym Trainer">Gym Trainer</option>
            <option value="Geologist">Geologist</option>
            <option value="HR Executive"> HR Executive</option>
            <option value=" HR Manager"> HR Manager</option>
            <option value="Hotel Manager"> Hotel Manager</option>
            <option value="Housekeeping Staff">Housekeeping Staff</option>
            <option value="IT Support Engineer"> IT Support Engineer</option>
            <option value="Interior Designer">Interior Designer</option>
            <option value="Insurance Agent">Insurance Agent</option>
            <option value="Instructional Designer">
              Instructional Designer
            </option>
            <option value="Journalist">Journalist</option>
            <option value="Java Developer"> Java Developer</option>
            <option value="Lab Technician"> Lab Technician</option>
            <option value="Lecturer">Lecturer</option>
            <option value="Marketing Manager">Marketing Manager</option>
            <option value="Mechanical Engineer">Mechanical Engineer</option>
            <option value="Network Engineer">Network Engineer</option>
            <option value="Office Assistant">Office Assistant</option>
            <option value="Office Manager"> Office Manager</option>
            <option value="Pharmacist"> Pharmacist</option>
            <option value="Project Manager"> Project Manager</option>
            <option value="Product Manager">Product Manager</option>
            <option value="Public Relations Officer">
              Public Relations Officer
            </option>
            <option value="Quality Analyst">Quality Analyst</option>
            <option value="QA Tester">QA Tester</option>
            <option value="Receptionist">Receptionist</option>
            <option value="Retail Sales Executive">
              Retail Sales Executive
            </option>
            <option value="Sales Executive">Sales Executive</option>
            <option value="Software Developer"> Software Developer</option>
            <option value="System Administrator">System Administrator</option>
            <option value="Social Media Manager">Social Media Manager</option>
            <option value="Teacher">Teacher</option>
            <option value="Telecaller">Telecaller</option>
            <option value="UX/UI Designer"> UX/UI Designer</option>
            <option value="Video Editor">Video Editor</option>
            <option value="Web Developer">Web Developer</option>
            <option value="Yoga Instructor">Yoga Instructor</option>
            <option value="App Developer">App Developer</option>
            <option value="Data Scientist">Data Scientist</option>
            <option value="JavaScript Developer">JavaScript Developer</option>
            <option value="Sales Manager">Sales Manager</option>
            <option value="Cloud Engineer">Cloud Engineer</option>
            <option value="DevOps Engineer">DevOps Engineer</option>
            <option value="Cybersecurity Engineer">
              Cybersecurity Engineer
            </option>
                
          </select>
          </div>

          <div className="form-group mt-3">
          <label htmlFor="" className='form_label' >* Optional: If you don't find the designation, enter it manually.  </label>
            <input type="text" id="Position" name="position" placeholder='Designation' />
          </div>
          <div className="form-group1 mt-3">

            <label htmlFor="" className='form_label'>Resume/Cv</label>            
            <input type="file" id="pdfFile" name="attachment" accept="application/pdf" className='mb-3' required />
          </div>
          
          <button type="submit" className="submit-btn">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default CareerPopup;
