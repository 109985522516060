import React from 'react'
import { TypeAnimation } from 'react-type-animation';
import './typer.css'

const typer = () => {
  return (
    <div>
         <TypeAnimation
        sequence={[
          'Searching for your dream job?',
          1000,
          'Looking for the perfect job?',
          1000,
          'Find top companies today!',
          1000,
          'Connecting job seekers...',
          1000,
        ]}
        className='tpanim'
        wrapper="span"
        speed={50}
        // style={{ fontSize: '2em', display: 'inline-block', color: 'white' , fontWeight:'normal' }}
        repeat={Infinity}
      />
    </div>
  )
}

export default typer