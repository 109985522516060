import React from "react";
import "./about.css";
import crystal from "../../asset/jini.gif";
import Spacer from "../spacer/spacer.jsx"
const about = () => {
  return (
    <>
      <div className="container">
       <Spacer/>
        <h1 className="abt_title">About Us</h1>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-sm-12 text-center">
            <img src={crystal} alt="" className="crystal" />
          </div>
          <div className="col-lg-6 col-sm-12">
            <p className="abt__content">
              At Betterjob, we strongly believe that people constitute the very
              foundation of any business. As the best HR consultancy, we will
              empower your businesses with the right talent and tailor-made HR
              Solutions to help maximize its potential. Our mission is to
              empower companies to build strong, effective teams that then
              propel success. Our goal is to become more than a consulting
              provider for you; we aim to build a growth relationship by
              identifying your unique needs and presenting solutions to meet
              those needs—solutions that reflect your goals.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default about;
