import React from 'react';
import './formhire.css'

const HirePopup = ({ togglePopup }) => {
  return (
    <div className="career-popup-overlay" onClick={ togglePopup}>
      <div className="career-popup" onClick={(e) => e.stopPropagation()}>
        <h2 className='text-capitailize'>I want to hire</h2>
        <form  method="POST" action="https://formsubmit.co/betterjobkerala@gmail.com" enctype="multipart/form-data" >
          <div className="form-group mt-3">
            <label htmlFor="" ></label>
           <input type="text" name="name" placeholder='Name' id="" />
          </div>
          <div className="form-group mt-3">
            <input type="text" name="Company Name" placeholder='Company Name' />
          </div>
          
          <div className="form-group mt-3">
          <label htmlFor="" ></label>
            <input type="email" id="email" name="Email" placeholder='Email' required />
          </div>

          <div className="form-group mt-3">
          <label htmlFor="" ></label>
            <input type="tel" id="phoneNumber" name="phoneNumber" placeholder='Contact' required />
          </div>
         <div className="form-group mt-3 mb-3">
            <label htmlFor="" ></label>
            <input type="text" name="website" id="" placeholder='Website' />
         </div>
          
          <button type="submit" className="submit-btn">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default HirePopup;
