import React from 'react'
import '../button/button.css'

const button = ({ onClick, label}) => {
  return (
    <div>
         <button className='mt-4 buttonui' onClick={onClick}>
            <span>{label}</span>
         </button>

    </div>
  )
}

export default button