import React from "react";
import "./contact.css";
import crystal from "../../asset/jini.gif";
import Button from "../home/button/button.jsx";

const contact = () => {
  return (
    <>
  
      <div className="container">
        <h1 className="cont_title mx-2">Contact</h1>
      </div>
      <div className="container">
        <div className="cont_card">
          <div className="row">
            <div className="col-lg-5 col-sm-12 text-center">
              <img src={crystal} alt="" className="con_img" />
            </div>
            <div className="col-1">
              <div className="vertical-line"></div>
            </div>
            <div className="col-lg-6 col-sm-12 text-center">
              <form  className="getintouch" action="https://formsubmit.co/betterjobkerala@gmail.com" method="POST">
                <h2 className="cont_title">Get in Touch</h2>
                <input type="text" name="name" id="" placeholder="Fullname" required/>
                <input type="email" name="email" id="" placeholder="Email" required/>
                <input type="tel" name="contact" placeholder="Contact" required/>
                <textarea
                  name="message"
                  id=""
                  cols="10"
                  rows="4"
                  placeholder="Message"
                ></textarea>
                <Button label="submit" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default contact;
