import React from 'react'

const footer = () => {
  return (
   <>
  <div className="container">
    <div className="row">
    <p className='text-center text-light'></p>
    </div>
  </div>
   </>
  )
}

export default footer