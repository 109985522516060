import React, { useState } from "react";
import Button from "./button/button.jsx";
import Typer from "./typer/typer.jsx";
import Deco from "../../asset/jini.gif";
import CareerPopup from "./enrolform/form.jsx"
import HirePopup from "./hireform/formhire.jsx"
import Spacer from "../spacer/spacer.jsx"
import "./home.css";



const Home = () => {

  const [showCareerPopup, setShowCareerPopup] = useState(false);
  const [showHirePopup, setShowHirePopup] = useState(false);


  const handleShowCareerPopup = () => setShowCareerPopup(true);
  const handleCloseCareerPopup = () => setShowCareerPopup(false);
  const handleShowHirePopup = () => setShowHirePopup(true);
  const handleCloseHirePopup = () => setShowHirePopup(false);


  return (
    <>
      <div className="container">
        <div className="row">
          <Spacer/>
          <div className="col-md-7">
            <h1 className="text-start mt-5">Welcome to Better Job</h1>
            <Typer />
            <p className="">Always ready to make your life better</p>
            <div className="row">
              <div className="col d-flex gap-4 justify-content-start">
                <Button onClick={handleShowCareerPopup} label="I need a Job" />
                <Button onClick={handleShowHirePopup} label="I want to Hire" />
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <img src={Deco} alt="" className="deco" />
          </div>
        </div>
      </div>
      {showCareerPopup && (
        <CareerPopup togglePopup={handleCloseCareerPopup} />
      )}
      {
        showHirePopup && (
          <HirePopup togglePopup={handleCloseHirePopup} />)
      }

     
    </>
  );
};

export default Home;
