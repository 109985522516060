import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../asset/logo.svg';

const Navbar = () => {
  const [activeTab, setActiveTab] = useState(window.location.pathname);

  const handleNavCollapse = () => {
    document.querySelector('.navbar-collapse').classList.remove('show');
  };

  const handleScroll = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 50, // Adjust the offset for fixed header
        behavior: 'smooth',
      });
    }
    setActiveTab(sectionId);
    handleNavCollapse();
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-transparent navbar-white">
        <div className="container d-flex justify-content-between align-items-center">
          <NavLink to="/" className="navbar-brand fw-bold fs-4" onClick={() => handleScroll('home')}>
            <img src={Logo} alt="Logo" className="btrjbLogo" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <FontAwesomeIcon icon={faBars} className="custom-hamburger-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink
                  to="/"
                  className={`nav-link ${activeTab === 'home' ? 'active' : ''}`}
                  onClick={() => handleScroll('home')}
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/"
                  className={`nav-link ${activeTab === 'about' ? 'active' : ''}`}
                  onClick={() => handleScroll('about')}
                >
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/"
                  className={`nav-link ${activeTab === 'contact' ? 'active' : ''}`}
                  onClick={() => handleScroll('contact')}
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
